.about-container {
  float: right;
 
  margin-left: 40px;
  text-align: left;
  width:700px;
}

.about-container a:hover {
  background: black;
  color:white;
}

.about-name {
  font-weight: 500;
  font-size: 45px;
  display: inline-block;
}

.about-contact {
  margin-bottom: 35px;
}

@media only screen and (max-width: 1000px) {
  .about-container {
    width: 100%;
    margin-left: 0;
  }

  .about-name {
    font-size: 30px;
  }

  .about-contact {
    margin-bottom: 15px;
  }
}
