@import "./utils/variables.css";

.main-container {
  width: 100%;
  height: 100%;
  padding: var(--main-container-padding);
  box-sizing: border-box;
  display: flex;
}

.content-container {
  text-align: center;
  height: 100%;
  flex-grow: 1;
}
