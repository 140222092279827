.photography-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.photo-container {
  width: 60%;
  height: 90%;
  position: absolute;
  left: 20%;
  top: 5%;
}
.photo-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photo-mouse-box {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  background: lightgrey;
  display: inline-block;
}

.photo-left-box {
  width: 50%;
  height: 100%;
  float: left;
  opacity: 0;
  cursor: w-resize;
  user-select: none;
}
.photo-right-box {
  width: 50%;
  float: right;
  opacity: 0;
  height: 100%;
  cursor: e-resize;
  user-select: none;
}

@media only screen and (max-width: 1000px) {
  .photo-container {
    width: 80%;
    left: 10%;
  }
}
