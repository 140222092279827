html,
#root,
body {
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
  font-size: var(--font-size);
}