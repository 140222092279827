.home-main-container {
  width: 1000px;
  height: 100%;
  float: right;
}

.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.home-grid-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

.home-container-column {
  width: 32%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.home-column {
  color: white;
  background: black;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  cursor: default;
}

.home-column:first-child {
  margin-left: 0;
}

.home-column:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1400px) {
  .home-main-container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .home-grid-container {
    grid-template-columns: auto;
    grid-template-rows: auto;
    column-gap: 0px;
    row-gap: 20px;
    box-sizing: border-box;
  }
}
