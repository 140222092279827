.sidebar-container {
  position: relative;
  margin-right: 150px;
}

.sidebar {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 500;
}

.sidebar-second {
  position: absolute;
  padding-left: 5px;
  left: 100%;
  top: 0;
  z-index: 100;
  font-weight: 300;
}

.sidebar-item {
  padding: var(--sidebar-item-padding);
  cursor: pointer;
}

.sidebar-item:hover {
  opacity: 1;
  background: black;
  color: white;
}

a {
  color: black;
  text-decoration: none;
  transition: 0.1s;
}

.sidebar-second a {
  background: white;
  color: black;
}

.invisible {
  visibility: hidden;
}
