.column-container {
  font-weight: 500;
  opacity: 0;
  transition: 0.2s;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column-container:hover {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.column-title {
  margin: 5px;
  font-size: 25px;
}

.column-synopsis {
  margin-top: 10px;
  font-weight: 300;
  text-align: left;
  font-size: 16px;
}

.column-data {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 15px;
  font-weight: 300;
}

.column-data span {
  font-weight: 500;
}

.column-logo img {
  width: 20%;
  margin: 10px;
}

.column-button {
  padding: 10px;
  border: 2px solid white;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  transition: 0.2s;
  color: white;
}

.column-button:hover {
  background: white;
  color: black;
}


@media only screen and (max-width: 1000px) {
  .column-container {
    opacity: 1;
  }
}
