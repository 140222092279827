.topbar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: white;
}

.topbar-second {
  margin-left: 20px;
}

.topbar-second a {
  font-weight: 300;
}

.topbar-top-container {
  width: auto;
  height: 70px;
}

.topbar-menu-icon-container {
  height: 70px;
  width: 70px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.topbar-icon {
  height: 100%;
  width: 100%;
  background: black;
}

.topbar-bottom-container {
  padding: 00px 20px 10px 20px;
}

.topbar-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
