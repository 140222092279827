::selection {
  background: #eeeeee;
}
::-moz-selection {
  background: black;
  color: white;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --main-container-padding: 30px;
  --font-size: 18px;
  --sidebar-item-padding: 6px;
}
@media only screen and (max-width: 1000px) {
  :root {
    --main-container-padding: 70px 20px 20px 20px;
    --font-size: 15px;
    --sidebar-item-padding: 6px 6px 6px 0px;
  }
}
